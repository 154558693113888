import React, { useEffect, useState } from "react"
import ReactPlayer from "react-player"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import VideoSlider from "../components/sliders/video-slider"
import VideoSliderSecond from "../components/sliders/video-slider-duplicate"
import BlogSlider from "../components/sliders/video-slider-three-col"
import {
  ArrowRightIcon,
  ArrowLongRightIcon,
  XMarkIcon,
  PlayIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/solid"
import Uploader from "../components/drop-zone/uploader"
import { useForm } from "react-hook-form"
import firebase from "firebase/compat/app"
import { db, storage } from "../firebaseConfig"
import { event } from "jquery"
import { ArrowLeftIcon, ChevronLeftIcon } from "@heroicons/react/24/outline"
import { ref, getDownloadURL, uploadBytes } from "@firebase/storage"
import { updateDoc, doc } from "firebase/firestore"
const { format } = require('date-fns');

const AwruduPage = ({ data }) => {
  const [formImage, setFormImage] = useState([])
  const [success, setSuccess] = useState()
  const [loading, setLoading] = useState(false)
  const [submitError, setSubmitError] = useState(null)
  const [imageLoading, setImageLoading] = useState(false)
  const [imageValidation, setImageValidation] = useState(false)
  const [calenderActive, setCalenderActive] = useState(15)

  const [visible, setVisible] = useState(8)
  const [popUp, setPopUp] = useState(false)
  const [active, setActive] = useState()
  const [month, setMonth] = useState(true)

  let formImageURLs = []

  // console.log("storage", storage)
  // console.log("db", db)

  const getData = file => {
    setImageValidation(file.length == 0)
    setFormImage(file)
  }

  const {
    trigger,
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm()

  const submitContactRequest = async data => {
    let newData = Object.assign({}, data)
    newData.createdAt = firebase.firestore.FieldValue.serverTimestamp()
    await db
      .collection("kruthagunaDawatana")
      .add({ ...newData, images: formImageURLs })
  }

  function refreshPage() {
    window.location.reload(false)
  }

  useEffect(() => {
    const { format } = require('date-fns');
    const today = format(new Date(), 'dd');
    const Ftoday = parseInt(today, 10);
    setCalenderActive(Ftoday - 1)

  }, [])

  const sendGridFirebase = async data => {
    fetch(
      "https://us-central1-watawala-website.cloudfunctions.net/kruthagunaDawatana",
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({ ...data, images: formImageURLs }),
      }
    )
      .then(response => response.json())
      .then(result => {
        console.log(result)
      })
  }

  // function imageValidationCheck() {
  //   if (formImage.length == 0) {

  //     // console.log(formImage.length)
  //   } else {
  //     handleSubmit(onSubmit)
  //   }
  // }

  const onSubmit = async data => {
    setLoading(true)
    try {
      for (let i = 0; i < formImage.length; i++) {
        const imageUrl = await uploadImage(
          formImage,
          `images/kruthaguna-dawatana-images/${data.name}/${Date.now()}`,
          i
        )
        formImageURLs.push(imageUrl)
        setImageLoading(i + 1)
        if (imageLoading == formImage.length - 1) {
          setImageLoading(false)
        }
      }
      await submitContactRequest(data)
      // data.push(formImageURLs)
      await sendGridFirebase(data)

      console.log("on Submit function called")
      setLoading(false)
      setSuccess(true)
      setTimeout(() => {
        setSuccess(false)
      }, 3000)
      setTimeout(() => {
        refreshPage()
      }, 3000)
      setSubmitError(null)
      setFormImage(null)
      reset()
      console.log("submited")
    } catch (error) {
      setLoading(false)
      setSuccess(false)
      setSubmitError("Submit Error occurred!")
      console.log(error.message)
    }
  }

  const images = {
    fruidBg: convertToBgImage(getImage(data.fruidBg)),
    header: convertToBgImage(getImage(data.header)),
    apple: getImage(data.apple),
    energy: getImage(data.energy),
    enhance: getImage(data.enhance),
    esteem: getImage(data.esteem),
    apple: getImage(data.apple),
    gardning: getImage(data.gardning),
    money: getImage(data.money),
    rewards: getImage(data.rewards),
    workout: getImage(data.workout),
    gallery_1: getImage(data.gallery_1),
    gallery_2: getImage(data.gallery_2),
    gallery_3: getImage(data.gallery_3),
    gallery_4: getImage(data.gallery_4),
    gallery_5: getImage(data.gallery_5),
    gallery_6: getImage(data.gallery_6),
    gallery_7: getImage(data.gallery_7),
    gallery_8: getImage(data.gallery_8),
  }

  const gallery = [
    { image: images.gallery_1 },
    { image: images.gallery_2 },
    { image: images.gallery_3 },
    { image: images.gallery_4 },
    { image: images.gallery_5 },
    { image: images.gallery_6 },
    { image: images.gallery_7 },
    { image: images.gallery_8 },
  ]

  const calenderApril = [
    {
      number: 1,
      event: true,
      truckOperation: "ඇල්පිටිය",
      houseToHouse: "බද්දේගම - නව නගරය",
    },
    {
      number: 2,
      event: true,
      truckOperation: "හික්කඩුව",
      houseToHouse: "බටපොල - තිලකපුර",
    },
    {
      number: 3,
      event: true,
      truckOperation: "ඌරගහ",
      houseToHouse: "ඇල්පිටිය - අනුරාගම",
    },
    {
      number: 4,
      event: true,
      truckOperation: "ගාල්ල",
      houseToHouse: "හික්කඩුව - පන්විල",
    },
    {
      number: 5,
      event: true,
      truckOperation: "මාතර",
    },
    {
      number: 6,
      event: true,
      truckOperation: "මතුගම",
    },
    {
      number: 7,
      event: true,
      truckOperation: "හොරණ",
    },
    {
      number: 8,
      event: true,
      truckOperation: "බණ්ඩාරගම",
    },
    {
      number: 9,
      event: true,
      truckOperation: "මොරටුව",
    },
    {
      number: 10,
    },
    {
      number: 11,
    },
    {
      number: 12,
    },
    {
      number: 13,
    },
    {
      number: 14,
    },
    {
      number: 15,
    },
    {
      number: 16,
    },
    {
      number: 17,
    },
    {
      number: 18,
    },
    {
      number: 19,
    },
    {
      number: 20,
    },
    {
      number: 21,
    },
    {
      number: 22,
    },
    {
      number: 23,
    },
    {
      number: 24,
    },
    {
      number: 25,
    },
    {
      number: 26,
    },
    {
      number: 27,
    },
    {
      number: 28,
    },
    {
      number: 29,
    },
    {
      number: 30,
    },
    {
      number: 31,
    },
  ]

  const calenderMarch = [
    {
      number: 1,
    },
    {
      number: 2,
    },
    {
      number: 3,
    },
    {
      number: 4,
    },
    {
      number: 5,
    },
    {
      number: 6,
    },
    {
      number: 7,
    },
    {
      number: 8,
    },
    {
      number: 9,
    },
    {
      number: 10,
    },
    {
      number: 11,
    },
    {
      number: 12,
    },
    {
      number: 13,
    },
    {
      number: 14,
    },
    {
      number: 15,
    },
    {
      number: 16,
      event: true,
      truckOperation: "නිකවැරටිය",
    },
    {
      number: 17,
      event: true,
      truckOperation: "ආණමඩුව",
    },
    {
      number: 18,
      event: true,
      truckOperation: "කුරුණෑගල",
    },
    {
      number: 19,
      event: true,
      truckOperation: "වාරියපොළ",
    },
    {
      number: 20,
      event: true,
      truckOperation: "කුලියාපිටිය",
    },
    {
      number: 21,
      event: true,
      truckOperation: "ගලිගමුව",
      houseToHouse: "වැලිගම - දෙනිපිටිය",
    },
    {
      number: 22,
      event: true,
      truckOperation: "ගිරිඋල්ල",
      houseToHouse: "අහංගම - කතළුව",
    },
    {
      number: 23,
      event: true,
      truckOperation: "නාරම්මල",
      houseToHouse: "මාතර -වැල්ලමඩම",
    },
    {
      number: 24,
      event: true,
      truckOperation: "පොතුහැර",
      houseToHouse: "තංගල්ල - ගල්පොත්තාගොඩ",
    },
    {
      number: 25,
      event: true,
      truckOperation: "අලව්ව",
      houseToHouse: "වලස්මුල්ල - කොස්කබලිහේන",
    },
    {
      number: 26,
      event: true,
      truckOperation: "ගාල්ල",
      houseToHouse: "ඉමදූව - හේනේගම",
    },
    {
      number: 27,
      event: true,
      truckOperation: "බලපිටිය",
      houseToHouse: "කඹුරුපිටිය - පලොල්පිටිය",
    },
    {
      number: 28,
      event: true,
      truckOperation: "අම්බලන්ගොඩ",
      houseToHouse: "ගාල්ල - පින්නදූව",
    },
    {
      number: 29,
      event: true,
      truckOperation: "රත්ගම",
      houseToHouse: "බලපිටිය",
    },
    {
      number: 30,
      event: true,
      truckOperation: "බද්දේගම",
      houseToHouse: "අම්බලන්ගොඩ - ගොඩහේන",
    },
    {
      number: 31,
      event: true,
      truckOperation: "බටපොල",
      houseToHouse: "රත්ගම",
    },
  ]

  return (
    <Layout>
      <Seo title="කෘතගුණ දවටන | Watawala" />
      <section>
        <div>
          <BackgroundImage
            {...images.header}
            className="w-full responsive-header relative"
          >
            <div className=" h-full ">
              <div className="container mx-auto h-full md:px-20 px-10 ">
                <div className="flex xl:flex-row flex-col pt-24 items-center justify-center xl:justify-between h-full z-10 relative">
                  <div className="flex xl:justify-start xl:mb-0 mb-10 justify-center">
                    <StaticImage
                      src="../images/brand/promotion logo awrudu.png"
                      class="w-2/3 xl:w-full"
                      alt="watawala"
                    />
                  </div>
                  <div>
                    <div className="rounded-xl   bg-white p-5">
                      <div className="flex items-center gap-0 justify-between text-black/60 border-b pb-5 border-black/50 h-[130px]">
                        <div className="">
                          <div className="text-3xl font-semibold">
                            {calenderActive + 1}
                          </div>
                          <div className="mt-1 font-light text-xs sm:text-sm">
                            {month ? "April" : "March"} 2023
                          </div>
                        </div>
                        <div>
                          <div className="mt-1 sm:text-base text-[13px] font-light mb-1">
                            {month ? <>අප්‍රේල්</> : <>මාර්තු</>}{" "}
                            {calenderActive + 1} වෙනිදා අපි එන ප්‍රදේශ
                          </div>
                          <div className="w-fit ml-auto">
                            {month ? (
                              <>
                                <div
                                  className={`${calenderApril[calenderActive].truckOperation
                                    ? "mt-1 font-light sm:text-base text-[13px] text-black bg-yellow px-3 py-1 rounded-full w-fit ml-auto"
                                    : "hidden"
                                    }`}
                                >
                                  {" "}
                                  {calenderApril[calenderActive].truckOperation}
                                </div>
                                <div
                                  className={`${calenderApril[calenderActive].houseToHouse
                                    ? "mt-1 font-light sm:text-base text-[13px] text-black  bg-yellow px-3 py-1 rounded-full w-fit ml-auto"
                                    : "hidden"
                                    }`}
                                >
                                  {" "}
                                  {calenderApril[calenderActive].houseToHouse}
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  className={`${calenderMarch[calenderActive].truckOperation
                                    ? "mt-1 font-light sm:text-base text-[13px]  text-black bg-yellow px-3 py-1 rounded-full w-fit ml-auto"
                                    : "hidden"
                                    }`}
                                >
                                  {calenderMarch[calenderActive].truckOperation}
                                </div>
                                <div
                                  className={`${calenderMarch[calenderActive].houseToHouse
                                    ? "mt-1 font-light sm:text-base text-[13px] text-black  bg-yellow px-3 py-1 rounded-full w-fit ml-auto"
                                    : "hidden"
                                    }`}
                                >
                                  {" "}
                                  {calenderMarch[calenderActive].houseToHouse}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center justify-between mt-5">
                        <div>
                          <ChevronLeftIcon
                            className={
                              month
                                ? "w-4 cursor-pointer "
                                : "w-4 text-lightgray cursor-not-allowed"
                            }
                            onClick={() => {
                              if (month === false) {
                                setMonth(month)
                              } else {
                                setMonth(!month)
                              }
                            }}
                          />
                        </div>
                        <div className="text-xs sm:text-sm font-medium text-black/60">
                          {month ? "April" : "March"}
                        </div>
                        <div>
                          <ChevronRightIcon
                            className={
                              month
                                ? "w-4 text-lightgray cursor-not-allowed"
                                : "w-4 cursor-pointer "
                            }
                            onClick={() => {
                              if (month === true) {
                                setMonth(month)
                              } else {
                                setMonth(!month)
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="grid grid-cols-7 gap-0 sm:gap-3 mt-5 font-light text-sm text-black/50">
                        <div className="uppercase">MON</div>
                        <div className="uppercase">TUE</div>
                        <div className="uppercase">WED</div>
                        <div className="uppercase">THU</div>
                        <div className="uppercase">FRI</div>
                        <div className="uppercase">SAT</div>
                        <div className="uppercase">SUN</div>
                      </div>
                      <div className="grid grid-cols-7 gap-0 sm:gap-3 mt-5 font-light text-sm text-black/50">
                        {month ? (
                          <>
                            {calenderApril.slice(0, 30).map((item, index) => {
                              return (
                                <div
                                  className={`p-2 px-3  rounded-full 
                              ${calenderActive == index && item.event == true
                                      ? "hover:bg-yellow bg-yellow"
                                      : "hover:bg-black/5"
                                    }
                              ${index == 0 ? "col-start-6" : ""} 
                              ${item.event ? "cursor-pointer" : "select-none"} 
                              `}
                                  onClick={() =>
                                    calenderApril[index].event == true
                                      ? setCalenderActive(index)
                                      : ""
                                  }
                                >
                                  <div className={`text-center `}>
                                    {item.number}
                                  </div>
                                  <div className={`flex justify-center`}>
                                    <div
                                      className={`p-[2px] ${item.event
                                        ? "bg-yellow"
                                        : "bg-transparent"
                                        } ${calenderActive == index &&
                                          item.event == true
                                          ? "bg-black/50"
                                          : ""
                                        } rounded-full`}
                                    ></div>
                                  </div>
                                </div>
                              )
                            })}
                          </>
                        ) : (
                          <>
                            {calenderMarch.map((item, index) => {
                              return (
                                <div
                                  className={`p-2 px-3  rounded-full 
                              ${calenderActive == index && item.event == true
                                      ? "hover:bg-yellow bg-yellow"
                                      : "hover:bg-black/5"
                                    }
                              ${index == 0 ? "col-start-3" : ""} 
                              ${item.event ? "cursor-pointer" : "select-none"} 
                              `}
                                  onClick={() =>
                                    calenderMarch[index].event == true
                                      ? setCalenderActive(index)
                                      : ""
                                  }
                                >
                                  <div className={`text-center `}>
                                    {item.number}
                                  </div>
                                  <div className={`flex justify-center`}>
                                    <div
                                      className={`p-[2px] ${item.event
                                        ? "bg-yellow"
                                        : "bg-transparent"
                                        } ${calenderActive == index &&
                                          item.event == true
                                          ? "bg-black/50"
                                          : ""
                                        } rounded-full`}
                                    ></div>
                                  </div>
                                </div>
                              )
                            })}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </BackgroundImage>
        </div>
      </section>

      <section>
        <div className="container mx-auto px-10 py-20">
          <div>
            <div className="flex justify-center">
              <div className="px-4 py-1 uppercase mb-4 bg-yellow/50 rounded-full text-brown font-thin">
                ව්‍යාපෘතිය
              </div>
            </div>
            <div className="uppercase text-3xl text-center mx-auto mb-4 tracking-widest">
              වටවල කෘතගුණ දවටන
            </div>
            <div className="max-w-xl text-center mb-7 mx-auto font-light text-lg">
              ඔබේ වටිනා වටවල දවටනය අප වෙත ලබාදී අවුරුදු සමරන්නට අපහසුතා ඇති තවත්
              පවුලකට අවුරුදු සිරි රැගෙන යන වටවල සත්කාර වැඩසටහන සමග ඔබත්
              එකතුවෙන්න.
            </div>
          </div>

          <div>
            <div className=" md:max-w-[73%] rounded-3xl overflow-hidden   mx-auto">
              <ReactPlayer
                url="https://firebasestorage.googleapis.com/v0/b/watawala-website.appspot.com/o/videos%2FResized.mp4?alt=media&token=61deb8d0-b96d-4f8f-b283-d20e066a719f"
                controls={true}
                playing={true}
                muted={false}
                width="100%"
                height="600px"
                playIcon={
                  <button className="p-8 rounded-full hover:bg-yellow duration-200 bg-white">
                    <PlayIcon className="w-12 text-brown" />
                  </button>
                }
                light="https://firebasestorage.googleapis.com/v0/b/watawala-website.appspot.com/o/images%2FCapture2.PNG?alt=media&token=7781b274-5f09-4abe-bfb3-dbf5291ad1b1"
              />
              {/* <div className="h-full w-full bg-[#23211B]/60 absolute top-0 z-10"></div> */}
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container md:px-20 px-10 mx-auto pb-20">
          <div className="flex justify-center">
            <div>
              <div className="flex justify-center">
                <div className="px-4 py-1 uppercase mb-4 bg-yellow/50 rounded-full text-brown font-thin">
                  ගැලරිය
                </div>
              </div>
              <div className="uppercase text-2xl max-w-sm text-center mx-auto mb-14 tracking-widest">
                ‘කෘතගුණ දවටන’ ව්‍යාපෘතියේ පින්තූර ගැලරිය
              </div>
            </div>
          </div>
          <div>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
              {gallery.slice(0, visible).map((item, index) => {
                return (
                  <div key={index}>
                    <div
                      onClick={() => {
                        setPopUp("true")
                        setActive(index)
                      }}
                    >
                      <GatsbyImage
                        alt="Watawala"
                        image={item.image}
                        className="cursor-pointer rounded-xl"
                      />
                    </div>
                    {popUp && (
                      <div className="relative">
                        <div className="bg-black/10 fixed z-50 top-0 left-0 w-full h-screen">
                          <XMarkIcon
                            className="absolute top-0 m-5 right-0 z-40 w-8 text-white cursor-pointer"
                            onClick={() => setPopUp(false)}
                          />
                          <div className="flex items-center justify-center  h-screen">
                            <GatsbyImage
                              alt="Watawala"
                              image={gallery[active].image}
                              className="w-1/2"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
          <div className="flex justify-center mt-7">
            <button
              className=" tracking-wide uppercase border border-black font-light rounded-full px-6 py-2 hover:bg-yellow hover:border-yellow duration-200"
              onClick={() => {
                setVisible(visible + 4)
              }}
            >
              <div className="">තවත් බලන්න</div>
            </button>
          </div>
        </div>
      </section>
      <section>
        <div className="bg-black/10" id="competition">
          <div className="container md:px-20 px-10 mx-auto py-20">
            <div className="flex justify-center">
              <div>
                <div className="flex justify-center">
                  <div className="px-4 py-1 uppercase mb-4 bg-yellow/50 rounded-full text-brown font-thin">
                    ඔබත් දායකවන්න
                  </div>
                </div>
                <div className="uppercase text-2xl max-w-sm text-center mx-auto mb-14 tracking-widest">
                  වටවල දවටන චායාරූප පහතින් එවන්න
                </div>
              </div>
            </div>
            <div>
              <form onSubmit={(e) => {
                e.preventDefault()
                trigger()
                if (formImage.length == 0) {
                  setImageValidation(true)
                } else {
                  handleSubmit(onSubmit)()
                }
              }}>
                <div className="text-sm mb-10">
                  ඔබේ නම
                  <span className="text-red-400">*</span>
                  <input
                    type="text"
                    className="border border-black p-3 focus:outline-none rounded-full w-full bg-white mt-3"
                    placeholder="ඔබේ නම ඇතුළත් කරන්න / Enter your name"
                    {...register("name", {
                      required: true,
                    })}
                    name="name"
                  />
                  {errors.name ? (
                    <>
                      {errors.name.message ? (
                        <>
                          <div className="text-xs text-red-400 font-firs-light mt-1">
                            {errors.name.message}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="text-xs text-red-400 font-firs-light mt-1">
                            නම හිස් විය නොහැක / Name cannot be empty
                          </div>
                        </>
                      )}
                    </>
                  ) : null}
                </div>

                <div className="text-sm mb-10">
                  ඔබේ දුරකතන අංකය
                  <input
                    type="text"
                    className="border border-black p-3 focus:outline-none rounded-full w-full bg-white mt-3"
                    placeholder="ඔබේ දුරකතන අංකය ඇතුළත් කරන්න / Enter your mobile number"
                    name="number"
                    {...register("number", {
                      required: true,
                      pattern: {
                        value: /^(\+\d{1,3}[- ]?)?\d{10}$/i,
                        message: "දුරකතන අංකය වලංගු නැත / Number is not valid",
                      },
                    })}
                  />
                  {errors.number ? (
                    <>
                      {errors.number.message ? (
                        <>
                          <div className="text-xs text-red-400 font-firs-light mt-1">
                            {errors.number.message}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="text-xs text-red-400 font-firs-light mt-1">
                            දුරකතන අංකය හිස් විය නොහැක / Number cannot be empty
                          </div>
                        </>
                      )}
                    </>
                  ) : null}
                </div>
                <div className="text-sm mb-10">
                  වටවල ප්‍රවර්ධන තේ පැකට් වල ඡායාරූප මෙතැනින් අමුණන්න
                  <Uploader getData={getData} />
                  {imageValidation && (
                    <div className="text-xs text-red-400 font-firs-light mt-1">
                      කරුණාකර අවම වශයෙන් එක් පින්තූරයක් උඩුගත කරන්න / Please upload at least one image
                    </div>
                  )}
                </div>
                <div className="uppercase font-light text-sm tracking-widest">
                  <div className=" flex justify-start">
                    <button
                      type="submit"
                      className=" tracking-wide uppercase border border-yellow bg-yellow text-black  font-light rounded-full px-6 py-2 hover:border-black duration-200 flex gap-3 items-center cursor-pointer"
                    >
                      {loading ? (
                        <>
                          <div className="flex items-center gap-2">
                            <Spinner />
                            <div className="text-xs">
                              {imageLoading && (
                                <>Uploading image {imageLoading}</>
                              )}
                            </div>
                          </div>
                        </>
                      ) : (
                        <> ඇතුළත් කරන්න</>
                      )}
                      <div>
                        <ArrowLongRightIcon className="h-4 w-4" />
                      </div>
                    </button>
                  </div>
                  {success && (
                    <div className="p-4 bg-emerald-500 text-center mt-5 rounded-full text-white">
                      Your submission has been sent.
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default AwruduPage

export const uploadImage = async (file, path, i) => {
  const storageRef = ref(storage, path)
  const snapshot = await uploadBytes(storageRef, file[i])
  return getDownloadURL(snapshot.ref)
}

const Spinner = () => {
  return (
    <>
      <svg
        role="status"
        className="inline w-7 h-7 text-white animate-spin dark:text-white/50 fill-white/50 dark:fill-white"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
    </>
  )
}

export const query = graphql`
  query AwruduPageImages {
    fruidBg: file(relativePath: { eq: "backgrounds/fruits.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    header: file(relativePath: { eq: "pages/awrudu/header.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    apple: file(relativePath: { eq: "icons/apple.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    energy: file(relativePath: { eq: "icons/energy.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    enhance: file(relativePath: { eq: "icons/enhance.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    esteem: file(relativePath: { eq: "icons/esteem.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gardning: file(relativePath: { eq: "icons/gardning.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    money: file(relativePath: { eq: "icons/money.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    rewards: file(relativePath: { eq: "icons/rewards.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    workout: file(relativePath: { eq: "icons/workout.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_1: file(
      relativePath: { eq: "pages/awrudu/gallery/gallery_1.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_2: file(
      relativePath: { eq: "pages/awrudu/gallery/gallery_2.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_3: file(
      relativePath: { eq: "pages/awrudu/gallery/gallery_3.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_4: file(
      relativePath: { eq: "pages/awrudu/gallery/gallery_4.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_5: file(
      relativePath: { eq: "pages/awrudu/gallery/gallery_5.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_6: file(
      relativePath: { eq: "pages/awrudu/gallery/gallery_6.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_7: file(
      relativePath: { eq: "pages/awrudu/gallery/gallery_7.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_8: file(
      relativePath: { eq: "pages/awrudu/gallery/gallery_8.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
  }
`
